<template>
    <div class="card-container" @click="onClick">
        <div class="card-header">
            订单编号：{{card.order_sn}}
        </div>
        <div class="card-cover">
            <img :src="card.cover_path"/>
            <div class="status-img" v-if="card.status != 1 && showCover">
                <img :src="statusCover"/>
            </div>
        </div>
        <div class="card-bottom">
            <slot></slot>
        </div>
    </div>
</template>

<script>
  const statusItems = [-1, 1, 2]
  export default {
    name: 'order-card-v1',
    props: {
      card: {
        type: Object,
        default: () => {
          return {
            card_code: '',
            cover: 'https://api.mengjingloulan.com/storage/wechat/coupon-card/card-cover.png',
            status: statusItems[Math.floor(Math.random() * statusItems.length)],
            //todo 这里需要设置好对应的状态
          }
        }
      },
      showCover:{
        type:Boolean,
        default: false
      }
    },
    mounted () {
      // console.log('this.$props.card', this.$props.card)
    },
    computed: {
      statusCover () {
        let status = -1
        if (this.$props.card.status == 1) {
          //todo
        }

        status = this.$props.card.status
        switch (status) {
          case -1:
            return ''
            break
          case 2:
            return this.statusImageArrays.alreadyGet
            break
          case 4:
            return this.statusImageArrays.expired
            break
        }
      }
    },
    data () {
      return {
        statusImageArrays: {
          alreadyGet: 'https://api.mengjingloulan.com/storage/wechat/coupon-card/already-get.png',
          expired: 'https://api.mengjingloulan.com/storage/wechat/coupon-card/expired.png'
          //已领取
        }
      }
    },
    methods:{
      onClick(){
        this.$emit('onClick')
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-container {
        display: flex;
        background: #fff;
        flex-wrap: wrap;
        padding: 10px 15px;
        margin-bottom: 10px;

        .card-header {
            height: 12px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            font-size: 12px;
        }

        .card-cover {
            width: 100%;
            height: 165px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .status-img {
                position: absolute;
                right: -1px;
                top: -2px;

                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .card-bottom {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>